<template>
	<div id="body">
		<div class="fatherBox">
			<div class="headerBox">
				<p>学生列表</p>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<el-button type="primary" @click="doExport">导出数据</el-button>
			</div>
			<div class="query">
				<div class="queryLeft">

					<div style="float: left ;width: fit-content;margin-right: 10px;">
						<el-date-picker style="width:133px;" v-model="check_date" align="right" type="date"
							placeholder="" :picker-options="pickerOptions">
						</el-date-picker>
						年级:
						<el-select v-model="gradeid" clearable @change="classSelect()" style="width: 100px;">
							<el-option v-for="(item,index) in gradeLists" :key="index" :label="item.TypeName"
								:value="item.id">
							</el-option>
						</el-select>
						班级:
						<el-select v-model="classid" clearable style="width: 100px;">
							<el-option v-for="(item,index) in classList" :key="index" :label="item.ClassName"
								:value="item.id">
							</el-option>
						</el-select>
						状态:
						<el-select v-model="staus" clearable style="width: 100px;">
							<el-option v-for="(item,index) in stateOptions" :key="index" :label="item.label"
								:value="item.id">
							</el-option>
						</el-select>
					</div>&nbsp;&nbsp;
					<div class="csxx">
						<el-button @click="reset()">重置</el-button>
						<el-button type="primary" @click="classListDataSearchfirst(1)">查询</el-button>
					</div>
				</div>
				<div class="queryRight" style="display: flex;">
					<div class="dataBox">
						<div class="num">{{ClassInfoss.all_num}}</div>
						<div class="text">学生数</div>
					</div>
					<div class="dataBox1">
						<div class="num">{{ClassInfoss.test_num}}</div>
						<div class="text">检测人数</div>
					</div>
					<div class="dataBox2">
						<div class="num">{{ClassInfoss.no_test_num}}</div>
						<div class="text">未检测人数</div>
					</div>
					<div class="dataBox3">
						<div class="num">{{ClassInfoss.normal_num}}</div>
						<div class="text">体温正常人数</div>
					</div>
					<div class="dataBox4">
						<div class="num">{{ClassInfoss.fever}}</div>
						<div class="text">发烧人数</div>
					</div>
					<div class="dataBox5">
						<div class="num">{{ClassInfoss.unusual_num}}</div>
						<div class="text">检测异常人数</div>
					</div>
				</div>
			</div>

			<el-table :data="ClassInfos" v-loading="loading">
				<el-table-column label="序号" type="index" width="auto" align="center"></el-table-column>
				<el-table-column label="姓名" prop="student_name" width="auto" align="center"></el-table-column>
				<el-table-column label="年级" prop="grade_name" width="auto" align="center"></el-table-column>
				<el-table-column label="班级" prop="class_name" width="auto" align="center"></el-table-column>
				<el-table-column label="测温时间" prop="create_date" width="auto" align="center"></el-table-column>
				<el-table-column label="环境温度" prop="env_degree" width="auto" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.env_degree}}℃</span>
					</template>
				</el-table-column>
				<el-table-column label="检测温度" prop="person_degree" width="auto" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.person_degree}}℃</span>
					</template>
				</el-table-column>
				<el-table-column label="测温照片" prop="then_photo" width="auto" align="center">
					<template slot-scope="scope">
						<el-image style="width: 160px; height: 90px" alt="测温照片" :src="scope.row.then_photo"
							:preview-src-list="[scope.row.then_photo]"  slot="placeholder">
							<div style=" margin-top: 36px;" slot="error" class="image-slot">
							        --
							 </div>
						</el-image>
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="auto" align="center">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="doView(scope.row)" plain>检测详情
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div v-show="CountNum > 0">
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
					style="margin-top:20px">
				</el-pagination>
			</div>
		</div>

	</div>
</template>

<script>
	import Vue from 'vue'
	import {
		gradeListDetail
	} from '@/api/SchoolApi.js'
	import {

		gradeClass
	} from '@/api/TeacherApi.js'

	import {
		mapGetters
	} from 'vuex'
	export default {
		computed: {
			...mapGetters(['Status'])
		},
		data() {
			return {
				stateOptions: [{
						label: '正常',
						id: 1
					},
					{
						label: '异常',
						id: 2
					},
					{
						label: '发烧',
						id: 3
					},
				],
				check_date: '', //检测日期
				class_id: this.$route.params.class_id,
				ClassInfoss: {},
				ClassInfos: [],
				loading: false, // 加载框
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				gradeid: '', // 年级查询id
				gradeLists: [], // 年级数组
				classid: '', // 班级查询id
				staus: '', //状态
				classList: [], // 班级数组
				dialogVisible: false,
				dialogVisibles: false,
				daterange: [],
				form: {
					day: '',
					gid: '', // 年级查询id
					cid: '', // 班级查询id
					bstatus: 1
				},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
				},

				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						}
					}, {
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						}
					}]
				},
				value1: '',
				value2: '',

			}
		},
		mounted() {
			this.ClassList(1) // 获取数据列表
			this.gradeListvue() // 获取年级信息
			// this.DataList() // 统计数据
		},
		methods: {
			reset() { // 重置按钮
				this.classList = []
				this.gradeid = ''
				this.classid = ''
				this.staus = ''
				this.currpage = 1
				this.ClassList(this.currpage)
			},
			ClassList(currpage) { // 班级数据管理列表
				this.loading = true
				const data = {
					page: currpage, // 当前页数
					limit: this.pagesize, //	每页条数
					class_id: this.class_id
				}
				gradeListDetail(data).then(res => {
					this.ClassInfoss = res.data.inAllInfo
					this.ClassInfos = res.data.list
					this.CountNum = parseInt(res.data.countNum) // 总条数
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},

			// 查看详情
			doView(item) {
				this.$router.push({ // 页面跳转
					name: 'testDetails', // 往这个页面跳转
					params: { // 跳转传递的参数
						student_id: item.student_id,
						student_name: item.student_name,
						grade_name: item.grade_name,
						class_name: item.class_name
					}
				})
			},

			handleCurrentChange(val) {
				if (this.gradeid == '') {
					this.ClassList(val)
					this.currpage = val
				} else {
					this.classListDataSearch(val) // 当有姓名条件时 分页操作
				}
			},
			classListDataSearchfirst(val) { // 只有当点击查询时候走这个方法

				const data = {
					page: val,
					limit: this.pagesize,
					class_id: this.classid,
					grade_id: this.gradeid,
					staus: this.staus,
					check_date: this.check_date

				}
				gradeListDetail(data).then(res => {
					this.currpage = 1
					this.ClassInfos = res.data.list
					this.CountNum = parseInt(res.data.countNum)
					this.loading = false
					console.log(res.data.list)
				}).catch(err => {
					console.log(err)
				})
			},

			classListDataSearch(val) { // 当第一次 点了查询条件后 分页走这个方法
				this.currpage = val
				const data = {
					page: val,
					limit: this.pagesize,
					class_id: this.classid,
					grade_id: this.gradeid,
					staus: this.person_degree,
					check_date: this.check_date

				}

				gradeListDetail(data).then(res => {
					this.ClassInfos = res.data.list
					this.CountNum = parseInt(res.data.CountNum)
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},




			gradeListvue() { // 年级列表
				const data = {
					type: 1 // 没用
				}
				gradeClass(data).then(res => {
					this.gradeLists = res.data
					console.log(res.data.list)
					console.log(this.gradeLists)
				}).catch(err => {
					console.log(err)
				})
			},
			classSelect() { // 班级列表
				this.classid = ''
				this.classList = []

				const data = {
					gid: this.gradeid
				}

				if (this.gradeid != "") {
					gradeClass(data).then(res => {
						this.classList = res.data;
					}).catch(err => {
						console.log(err)
					})
				}

			},
			doExport() { // 导出
				// const userInfo = JSON.parse(localStorage.getItem('userInfo'))
				// this.class_id = userInfo.ClassID
				const data = {
					page: this.currpage,
					limit: this.pagesize, //	每页条数
					class_id: this.class_id,

					export: 1
				}
				gradeListDetail(data).then(res => {


					this.Export = res.data.export_url
					console.log(res.data.export_url)
					window.open(this.Export)

				}).catch(err => {
					console.log(err)
				})
			},
			// classSelect () { // 班级列表
			//   this.form.cid = ''
			//   this.classList = []
			//   const data = {
			//     gid: this.form.gid
			//   }

			//   if (this.gid != '') {
			//     gradeClass(data).then(res => {
			//       this.classList = res.data
			//     }).catch(err => {
			//       console.log(err)
			//     })
			//   }
			// },
			//

		}

	}
</script>

<style lang="scss" scoped>
	#body {
		background: #f0f2f5;
	}

	.fatherBox {
		width: 98%;
		// height: 800px;
		display: flex;
		background: white;
		margin: 0 auto;
		flex-direction: column;
		justify-content: center;

		.headerBox {
			display: flex;
			height: 52px;
			width: 98%;
			align-items: center;
			justify-content: space-between;
			border-bottom: 2px solid #e9e9e9;

			p {
				font-size: 16px;
				font-family: 萍方-简;
				font-weight: normal;
				color: #000000;
				margin-left: 32px;
			}

			.btn {
				display: flex;
				align-items: center;
				width: 109px;
				height: 32px;
				opacity: 1;
				border-radius: 2px;
			}
		}

		.del {
			float: left,

		}

		.query {
			width: 98%;
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding-top: 20px;
			padding-left: 32px;

			.queryLeft {
				width: 75%;
				display: flex;
				flex-wrap: wrap;

				div {
					width: 174px;
					line-height: 50px;
					text-align: left;

					.input,
					.select {
						width: 60%;
					}
				}
			}
		}
	}

	.dataBox {
		padding: 5px 20px;
		font-size: 18px;
		cursor: pointer;

		.num {
			color: #333333;
			font-weight: 600;
			font-size: 30px;

		}

		.text {
			width: 60px;
			font-size: 20px;

		}
	}

	.dataBox1 {
		padding: 5px 20px;
		font-size: 18px;

		.num {
			font-weight: 600;
			font-size: 30px;
			color: #333333
		}

		.text {
			width: 80px;
			font-size: 1.25rem;

		}
	}

	.dataBox2 {
		padding: 5px 20px;
		font-size: 18px;

		.num {
			color: #027DB4;
			font-weight: 600;
			font-size: 30px;
		}

		.text {
			width: 100px;
			font-size: 1.25rem;
			color: #027DB4;

		}
	}

	.dataBox3 {
		padding: 5px 20px;
		font-size: 18px;

		.num {
			color: #4B7902;
			font-weight: 600;
			font-size: 30px;
		}

		.text {
			width: 120px;
			font-size: 1.25rem;
			color: #4B7902;

		}
	}

	.dataBox4 {
		padding: 5px 20px;
		font-size: 18px;

		.num {
			color: #D9001B;
			font-weight: 600;
			font-size: 30px;
		}

		.text {
			width: 100px;
			font-size: 1.25rem;
			color: #D9001B;

		}
	}

	.dataBox5 {
		padding: 5px 20px;
		font-size: 18px;

		.num {
			color: #BFBF00;
			font-weight: 600;
			font-size: 30px;
		}

		.text {
			width: 120px;
			font-size: 1.25rem;
			color: #BFBF00;

		}
	}
</style>
